import themes from "./themes";

export const EMAIL_REGEX = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export const STRONG_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
export const NON_NUMERIC_REGEX = /^[^\d]+$/;
export const tradeInAuthInitialValues = {
  agreement_one: false,
  agreement_two: false,
  products: {
    article_no: null,
    article_name: "",
    min_order_quantity: null,
    take_all_quantity: null,
    take_all_unit_price: null,
    unit_price: null,
    delivery_date: null,
    european_article_number: "",
    new_product: null,
  },
};
export const RFFSelectCountryCustomStyles = (meta) => ({
  menu: (provided) => ({
    ...provided,
    fontSize: "14px",

    color: themes.colors.titleColor,
    "::placeholder": {
      color: themes.colors.stepFourInputColor,
    },
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "6px",
    padding: "2px 5px",
    marginTop: "0.4rem",
    backgroundColor: themes.colors.transparent,
    borderColor:
      meta?.touched && meta?.error
        ? themes.colors.danger
        : themes.colors.stepFourInputBorder,
    color: themes.colors.titleColor,
    "::placeholder": {
      color: themes.colors.stepFourInputColor,
    },
  }),
  input: (provided) => ({
    ...provided,
    color: themes.colors.titleColor,
    height: "2.2rem",
    "::placeholder": {
      color: themes.colors.stepFourInputColor,
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: themes.colors.titleColor,
  }),
});
export const generateInitialProductValues = (data, randomArticleNo) => ({
  article_no: data ? data?.article_no : randomArticleNo,
  article_name: data ? data?.article_name : null,
  min_order_quantity: data ? data?.min_order_quantity : null,
  take_all_quantity: data ? data?.take_all_quantity : null,
  take_all_unit_price: data ? data?.take_all_unit_price : null,
  unit_price: data ? data.unit_price : null,
  delivery_date: data ? data.delivery_date : null,
  european_article_number: data ? data.european_article_number : null,
  new_product: data?.new_product || !(data && data?.direct_buy_price),
  direct_buy_price:
    data && data?.direct_buy_price !== -1 ? data?.direct_buy_price : -1,
  is_direct_buy: data ? data?.is_direct_buy : false,
  quantity_set: data
    ? data?.quantity_set > 100
      ? 100
      : data?.quantity_set
    : 100,
  max_char_limit: 50,
  max_ean_limit: 13,
  min_unit_price: 1,
  min_quantity_product: -1,
  min_article_limit: 7,
});
export const responseStatusSuccesCode = 200;
export const validCurreny = "€";
export const QTY = "QTY";
export const phoneNumber = "tel:+49 (0) 69 83 83 25 0";
export const mailTo = "mailto:info@yukatel.de";
export const MIN_WIDTH_FOR_MODAL = 1271;
export const refetchIntervalValues = {
  stockListValue: 5 * 60 * 1000,
  orderDetailValue: 15 * 1000,
};
export const welcomeStep = 0;
export const productsTableViewMinWidth = 768;
export const submitStatus = {
  SUCCESS: "success",
  ERROR: "error",
};
export const defaultCurrentPage = 1;
export const columnData = [
  {
    name: "product_description",
    width: { md: 199, xl: 17 },
  },
  {
    name: "european_article_number_short_and_part_number",
    width: { md: 115, xl: 12 },
  },
  {
    name: "unit_price",
    width: { md: 115, xl: 10.5 },
  },
  {
    name: "minimum_order_quantity",
    width: { md: 115, xl: 11 },
  },
  {
    name: "take_all_qty_price_optional",
    width: { md: 230, xl: 18.5 },
  },
  {
    name: "delivery_date",
    width: { md: 136, xl: 11 },
  },
  {
    name: "direct_buy",
    width: { md: 170, xl: 15.5 },
  },
  {
    name: "event",
    width: { md: 57, xl: 5 },
  },
];

export const rightInputStyle = {
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: "0.1em",
  width: "calc(100% - 70px)",
  fontWeight: 500,
  fontSize: "0.85em",
  borderLeft: `0.3px solid ${themes.colors.lightGray}`,
  color: themes.colors.primary,
  backgroundColor: themes.colors.lightGray,
};

export const productTableResolutions = {
  rowHeight: { minWidth: 1600, maxHeight: 96, minHeight: 60 },
  columnWidth: { minWidth: 1440, percent: 100 },
};

export const tradeInMaxInputValue = 99999;

export const listItems = [
  {
    title: "trade_in_home_section_one_list_item_one_title",
    text: "trade_in_home_section_one_list_item_one_text",
  },
  {
    title: "trade_in_home_section_one_list_item_two_title",
    text: "trade_in_home_section_one_list_item_two_text",
  },
  {
    title: "trade_in_home_section_one_list_item_three_title",
    text: "trade_in_home_section_one_list_item_three_text",
  },
  {
    title: "trade_in_home_section_one_list_item_four_title",
    text: "trade_in_home_section_one_list_item_four_text",
  },
  {
    title: "trade_in_home_section_one_list_item_five_title",
    text: "trade_in_home_section_one_list_item_five_text",
  },
  {
    title: "trade_in_home_section_one_list_item_six_title",
    text: "trade_in_home_section_one_list_item_six_text",
  },
];
export const debounceDelay = 300;
export const directBuyMaxTolerance = 20;
export const TradeIn_Status = {
  STATUS_APPROVED: {
    value: 1,
    text: "approved",
  },
  STATUS_REJECTED: {
    value: 2,
    text: "rejected",
  },
  STATUS_UNDER_REVIEW: {
    value: 3,
    text: "under_review",
  },
  STATUS_COUNTER_OFFER: {
    value: 4,
    text: "counter_offer",
  },
  STATUS_LOST_OPPURTUNITIES: {
    value: 5,
    text: "lost_oppurtunities",
  },
};

export const createRmaReturnDeviceType = {
  allDevices: 1,
  selectedDevice: 2,
};

export const TradeInInitialSubmission = [
  { text: "counter_offer" },
  { text: "under_review", textColor: "gray-texts" },
  { text: "approved", textColor: "green-texts" },
  { text: "rejected", textColor: "red-texts" },
  { text: "lost_oppurtunity", textColor: "gray-texts" },
];

export const OverviewTabMenuColumns = [
  {
    label: "counter_offer",
    value: TradeIn_Status.STATUS_COUNTER_OFFER.value,
    status: TradeIn_Status.STATUS_COUNTER_OFFER.text,
  },
  {
    label: "under_review",
    value: TradeIn_Status.STATUS_UNDER_REVIEW.value,
    status: TradeIn_Status.STATUS_UNDER_REVIEW.text,
  },
  {
    label: "approved",
    value: TradeIn_Status.STATUS_APPROVED.value,
    status: TradeIn_Status.STATUS_APPROVED.text,
  },
  {
    label: "rejected",
    value: TradeIn_Status.STATUS_REJECTED.value,
    status: TradeIn_Status.STATUS_REJECTED.text,
  },
  {
    label: "lost_oppurtunities",
    value: TradeIn_Status.STATUS_LOST_OPPURTUNITIES.value,
    status: TradeIn_Status.STATUS_LOST_OPPURTUNITIES.text,
  },
];

export const SocketConnetionType = {
  INITIAL_DATA: "initial_data",
  MESSAGE: "message",
  JOIN_CONVERSATION: "join_a_room",
  LEAVE_CONVERSATION: "leave_a_room",
  ONLINE: "online",
  OFFLINE: "offline",
  DISCONNECT: "disconnect",
  ERROR: "error",
  CONNECTION: "connection",
  RECONNECT: "reconnect",
  RECONNECTING: "reconnecting",
  RECONNECT_ERROR: "reconnect_error",
  RECONNECT_ATTEMPT: "reconnect_attempt",
};

export const SocketStatusEnum = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  LOADING: "loading",
  UNAUTHORIZED: "unauthorized",
};

export const SenderRole = {
  OPERATOR: "operator",
};

export const defaultPage = 1;

export const defaultLimit = 10;

export const SOCKET_URL = process.env.REACT_APP_CHAT_SOCKET_URL ?? "";

export const CHAT_API_URL = process.env.REACT_APP_CHAT_API_URL ?? "";

export const PageRoutes = {
  HOME: "/home",
  MY_ORDERS: "/account/my-orders",
  SHOP: "/shop",
  TRADE_IN: "/trade-in",
  TRADE_IN_OVERVIEW: "/trade-in/overview",
  PROFILE: "/profile",
  PRICE_LIST: "/price-list",
  RMA: "/rma",
  RMA_CREATE: "/rma/create",
  TRADE_IN_OLD_OFFER: "/trade-in-old-offer",
  HOME_TRADE_IN: "/home-trade-in",
  CHANGE_PASSWORD: "/change-password",
  CART: "/cart",
  TRADE_IN_HOME: "/trade-in-home",
  ACCOUNT: "/account",
  REDEEM_YP: "/redeem-yp",
};

export const MAX_CHAR_LIMIT = 500;

export const StockListSortyBy = {
  DESCRIPTION: "description",
  NEXT_DELIVERY_DATE: "next_delivery_date",
  SELLING_PRICE: "selling_price",
  YUKA_POINTS: "yuka_points",
};

export const SortDirection = {
  ASC: "asc",
  DESC: "desc",
};

export const RequestStatus = {
  SUCCESS: 200,
  ERROR: 403,
};

export const offerUsModalInitialValues = (tomorrow) => ({
  price: "",
  quantity: "",
  delivery_date: tomorrow,
});

export const statusMap = [
  {
    key: "counter-offer",
    color: themes.colors.yellow,
    status: TradeIn_Status.STATUS_COUNTER_OFFER.value,
  },
  {
    key: "under-review",
    color: themes.colors.borderGray,
    status: TradeIn_Status.STATUS_UNDER_REVIEW.value,
  },
  {
    key: "approved",
    color: themes.colors.lightGreen,
    status: TradeIn_Status.STATUS_APPROVED.value,
  },
  {
    key: "rejected",
    color: themes.colors.secondary,
    status: TradeIn_Status.STATUS_REJECTED.value,
  },
];

export const API_REQUEST_STATUS = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  OK_MAX_STATUS: 299,
};

export const BANNER_SETTINGS = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const PRODUCT_STOCK_STATUS = {
  AVAIBLE: "available",
  UPCOMING: "upcoming",
};

export const TELE_BESTELLEN = "ja";

export const SHOP_MENU = [
  {
    label: "item_description",
    value: "description",
    sortOption: "asc",
  },
  {
    label: "item_description",
    value: "description",
    sortOption: "desc",
  },
  {
    label: "yuka_points",
    value: "yuka_points",
    sortOption: "asc",
  },
  {
    label: "yuka_points",
    value: "yuka_points",
    sortOption: "desc",
  },
  {
    label: "price",
    value: "price",
    sortOption: "asc",
  },
  {
    label: "price",
    value: "price",
    sortOption: "desc",
  },
  {
    label: "est_delivery",
    value: "next_delivery_date",
    sortOption: "asc",
  },
  {
    label: "est_delivery",
    value: "next_delivery_date",
    sortOption: "desc",
  },
];

export const SHOP_RADIO_FILTER_DATA = [
  { id: "all", name: "all" },
  { id: "available", name: "available" },
  { id: "upcoming", name: "upcoming" },
  {
    id: "availablewithupcoming",
    name: "availablewithupcoming",
  },
  { id: "unavailable", name: "unavailable" },
];

export const PATH_NAMES = {
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
};
